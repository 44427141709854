// import { phone } from "phone";
import * as EIN from "ein-validator";
import { isValid as isSSNValid } from "ssn-validator";
import IBAN from "iban";
import * as TIN from "tin-validator";
import { USStateCodes } from "./constants";

export default {
  email: (value) => {
    return {
      isValid:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          `${value}`
        ),
      errorMessage: "Email is not valid.",
    };
  },
  companyName: (value) => {
    return {
      isValid: value?.length <= 50,
      errorMessage: "Company name has a 50 character limit.",
    };
  },
  firstName: (value) => {
    return {
      isValid: value?.length <= 45,
      errorMessage: "First name has a 45 character limit.",
    };
  },
  lastName: (value) => {
    return {
      isValid: value?.length <= 45,
      errorMessage: "Last name has a 45 character limit.",
    };
  },
  vendorName: (value) => {
    return {
      isValid: value?.length <= 255,
      errorMessage: "Vendor name has a 255 character limit.",
    };
  },
  phone: (value) => {
    return {
      isValid: /^\+\d{1,3}[- ()]?(\d{2,}[- ()]?\d{3,}|\d{4,})$/.test(
        value.replaceAll(" ", "").replaceAll("-", "")
      ),
      errorMessage: "Phone is not valid.",
    };
  },
  password: (value) => {
    return {
      isValid: /^(?=.*[0-9])(?=.*[!@#$%^&*_+=]).{12,42}$/.test(`${value}`),
      errorMessage: "Password is not valid.",
    };
  },
  longPassword: (value) => {
    return {
      isValid: /^(?=.*[0-9])(?=.*[!@#$%^&*_+=]).{18,42}$/.test(`${value}`),
      errorMessage: "Password is not valid.",
    };
  },
  isDateOnly: (value) => {
    return {
      isValid: /^\d{8}$/.test(`${value}`),
      errorMessage: "Date is not valid.",
    };
  },
  isHyphenAlphanumeric: (value) => {
    return {
      isValid: /^[a-zA-Z0-9-]+$/.test(`${value}`),
      errorMessage: "Input is not valid.",
    };
  },
  isAddress: (value) => {
    return {
      isValid: /^0x[a-fA-F0-9]{40}$/.test(`${value}`),
      errorMessage: "Address is not valid.",
    };
  },
  isHash: (value) => {
    return {
      isValid: /^0x[a-fA-F0-9]{64}$/.test(`${value}`),
      errorMessage: "Hash is not valid.",
    };
  },
  isHex: (value) => {
    return {
      isValid: /^0x[a-fA-F0-9]+$/.test(`${value}`),
      errorMessage: "Hexadecimal is not valid.",
    };
  },
  isSig: (value) => {
    return {
      isValid: /^0x[a-fA-F0-9]{130}$/.test(`${value}`),
      errorMessage: "Sig is not valid.",
    };
  },
  isVersion: (value) => {
    return {
      isValid: /^\d+\.\d+\.\d+$/.test(`${value}`),
      errorMessage: "Version is not valid.",
    };
  },
  isInteger: (value) => {
    return {
      isValid: /^\d+$/.test(`${value}`),
      errorMessage: "Integer is not valid.",
    };
  },
  isCountryCode: (value) => {
    return {
      isValid: /^[A-Z]{2}$/.test(`${value}`),
      errorMessage: "Country code is not valid.",
    };
  },
  isUrl: (value) => {
    return {
      isValid:
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00A1-\uFFFF][a-z0-9\u00A1-\uFFFF_-]{0,62})?[a-z0-9\u00A1-\uFFFF]\.)+(?:[a-z\u00A1-\uFFFF]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
          `${value}`
        ),
      errorMessage: "Url is not valid.",
    };
  },
  isPaymentAmount: (value) => {
    return {
      isValid: /^\d+(\.\d{1,2})?$/.test(value),
      errorMessage: "Amount is not valid.",
    };
  },
  isMinimumPaymentAmount: (value) => {
    return {
      isValid: parseFloat(value) >= 2,
      errorMessage: "Minimum payment amount is $2",
    };
  },
  isMinimumNumberOfPayments: (value) => {
    return {
      isValid: parseInt(value) >= 2,
      errorMessage: "Minimum number of payments is 2",
    };
  },
  ein: (str) => {
    return {
      isValid: EIN.isValid(str),
      errorMessage: "EIN is not valid.",
    };
  },
  ssn: (str) => {
    return {
      isValid: isSSNValid(str) || TIN.isValid(str),
      errorMessage: "SSN/ITIN is not valid.",
    };
  },
  taxIdLength: (str) => {
    return {
      isValid: str.length >= 4 && str.length <= 40,
      errorMessage:
        str.length < 4
          ? "Tax/National ID too short"
          : "Tax/National ID too long",
    };
  },
  isUSStateCode: (str) => {
    return {
      isValid:
        str &&
        (!!USStateCodes[str.toLowerCase()] ||
          Object.values(USStateCodes).includes(str.toUpperCase())),
      errorMessage: "State is not valid.",
    };
  },
  noUnknownCharacters: (str) => ({
    // eslint-disable-next-line no-useless-escape
    isValid: /^[À-ú-ZäöüÄÖÜ!-@.\/#&+\w\s]*$/.test(str),
    errorMessage: "Unknown characters are not allowed.",
  }),
  required: (value) => {
    return {
      isValid: value?.length > 0 || value === true,
      errorMessage: "This field is required.",
    };
  },
  weeklyHours: (value) => {
    return {
      isValid: value >= 1 && value <= 168,
      errorMessage: "Estimated weekly hours must be between 1 and 168.",
    };
  },
  iban: (value) => {
    return {
      isValid: IBAN.isValid(value),
      errorMessage: "IBAN is not valid.",
    };
  },
  accountingNumber: (value) => {
    return {
      isValid: /^[A-Za-z0-9 ]{6,34}$/.test(value),
      errorMessage: "Insert a valid IBAN or account number",
    };
  },
  sortCode: (value) => {
    return {
      isValid: /^[0-9]{2}\s?-?[0-9]{2}\s?-?[0-9]{2}$/.test(value),
      errorMessage: "Insert a valid sort code",
    };
  },

  gbpAccountNumber: (value) => ({
    isValid: /^\d{8}$/.test(`${value}`),
    errorMessage: "Account number is not valid.",
  }),
  notPOBox: (str) => ({
    isValid:
      !/^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i.test(
        str
      ),
    errorMessage: "PO Box is not allowed.",
  }),
};
